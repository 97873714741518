import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'
import { ArticlesByMonth, Sitemap } from '@jelly/templates'
import { groupContentByDate } from '@jelly/utils'

const META = {
	title: `Product and Service Reviews – businessnewsdaily.com`,
	description: `Find product and service reviews that can help grow your business`,
	robots: 'index, follow',
	canonical: `/sitemap/best-picks/`,
}

export default function BestPicks({ data }) {
	const filteredOutV2Urls = data.categories.filter((category) => !category.path.includes('-v2')) // filter out v2 urls. remove after VerticallyStacked Test is complete
	const groupedCategoriesByDate = groupContentByDate(filteredOutV2Urls)

	return (
		<Sitemap meta={META}>
			<ArticlesByMonth data={groupedCategoriesByDate} />
		</Sitemap>
	)
}

BestPicks.propTypes = { data: object, pageContext: object }

export const GatsbyQuery = graphql`
	query categories {
		categories(sort: { displayModified: DESC }) {
			displayModified
			path
			title
		}
	}
`
